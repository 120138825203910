import { useRouter } from 'next/navigation';
import type { KeyboardEvent, MouseEvent, SyntheticEvent } from 'react';
import type { GlycerinSuggestionType } from '@packages/tracking';
import { useRef, useState, Suspense, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Search32 } from '@packages/themes/icons';
import {
  Box,
  CloseIconButton,
  IconButton,
  TextField,
  Notification,
  MenuList,
  ErrorBoundary,
  CircularProgress,
  useDebounce,
  useI18n,
} from '@packages/shared';
import { useSession } from '@packages/utilities';
import { usePageTransition } from '@packages/shared/src/components/PageTransitionProvider/usePageTransition';
import { InspiringSearch } from './InspiringSearch';
import { useSearchQuery } from '../../useSearchQuery';
import { SearchHistory } from './SearchHistory';
import { SearchSuggestions } from './SearchSuggestions';
import { useSearchHistoryUpdate } from './useSearchHistoryUpdate';
import { useSearchFieldTracking } from './useSearchFieldTracking';
import type { Result, Suggestion } from './types';
import { SearchAnimatedPlaceholder } from './SearchAnimatedPlaceholder';
import { useInspiringSearchSettings } from '../../useInspiringSearchSettings';

const messages = defineMessages({
  placeholder: { id: 'searchbar.placeholder', defaultMessage: 'Lieblingsartikel suchen...' },
  ariaLabelInput: {
    id: 'searchbar.input.aria-label',
    defaultMessage: 'suchen-eingabe',
  },
  ariaLabelResetButton: {
    id: 'searchbar.resetbutton.aria-label',
    defaultMessage: 'zurücksetzen',
  },
  ariaLabelButton: {
    id: 'searchbar.button.aria-label',
    defaultMessage: 'suchen-button',
  },
  searchBarEmptyAlert: {
    id: 'searchbar.invalid.empty',
    defaultMessage: 'Bitte geben Sie zunächst Ihren Suchbegriff ein.',
  },
});

export const INITIAL_INPUT_VALUE = '';

const LoadingFallback = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      right: 52,
      zIndex: 'appBar',
      display: 'flex',
      transform: 'translateY(-50%)',
      bgcolor: 'common.white',
    }}
  >
    <CircularProgress size="s" />
  </Box>
);

export interface SearchFieldProps {
  /**
   * Sets focus on mounting
   * @default false
   */
  autofocus?: boolean;
  /** If `true`, don't show suggestions or history, and don't reflect current route in input
   * @default false
   * */
  isolated?: boolean;
  onSearch?: () => void;
  /** Optionally modify the URL that will be navigated to before navigation happens, e.g. to append query params */
  postProcessSearchUrl?: (url: string) => string;
}

/** Integrated search textfield component with iconbutton used in header */
export const SearchField = ({
  autofocus,
  isolated,
  onSearch,
  postProcessSearchUrl,
}: SearchFieldProps) => {
  const { dispatchSearchGtmEvent } = useSearchFieldTracking();
  const router = useRouter();
  const searchQuery = useSearchQuery();

  const { language, localizeUrl } = useI18n();

  const initialValue = searchQuery && !isolated ? searchQuery : INITIAL_INPUT_VALUE;

  const [focused, setFocused] = useState(false);
  // position of with keyboard selected item
  const [cursor, setCursor] = useState(-1);
  // value of search input
  const [value, setValue] = useState(initialValue);
  const [displayValue, setDisplayValue] = useState(initialValue);

  useSearchHistoryUpdate(searchQuery);
  const debouncedValue = useDebounce(value);
  // show alert when search triggered and input empty
  const [showAlert, setShowAlert] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const resetCursor = () => setCursor(-1);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // INFO: needs to get the jwt token here and provide to the popover components to avoid suspense + swr anti-pattern that the key can be null. update when swr completly implements suspense concept in further versions
  const { jwtToken } = useSession();

  const focus = () => {
    inputEl.current?.focus();
  };
  const onFocus = () => {
    setFocused(true);
  };
  const blur = () => {
    inputEl.current?.blur();
  };
  const onBlur = (event: SyntheticEvent<HTMLElement, FocusEvent>) => {
    const focusedNode = event?.nativeEvent.relatedTarget as Node | undefined;

    // keep the popup open if focus travels inside the popup, otherwise the popup will close immediately and focus will end up on the body
    if (focusedNode && wrapperRef.current?.contains(focusedNode)) {
      return;
    }

    setFocused(false);
  };

  const updateInput = (newValue: string) => {
    resetCursor();
    setValue(newValue);
    setDisplayValue(newValue);
  };

  const externalChangeValue = (newValue: string, displayOnly: boolean = true) => {
    setDisplayValue(newValue);

    if (!displayOnly) {
      setValue(newValue);
    }
  };

  const hideAlert = () => setShowAlert(false);

  const pageTransition = usePageTransition();

  // if input is empty show alert, else go to search route
  const search = () => {
    if (!displayValue) {
      setShowAlert(true);
      return;
    }

    // should work same as in the "old" shop system
    const url = `/s/${encodeURIComponent(displayValue.trim().replace(/[\s]+/g, '+')).replace('%2B', '+')}/`;
    const finalUrl = postProcessSearchUrl?.(url) ?? url;
    const localizedUrl = localizeUrl(finalUrl, language);

    if (pageTransition) {
      pageTransition.setUrl(localizedUrl);
      pageTransition.start(() => {
        router.push(localizedUrl);

        dispatchSearchGtmEvent(value || displayValue);
        onSearch?.();
      });
      // TODO maybe remove this branch after the whole app is migrated to the new app router
    } else {
      router.push(localizedUrl);

      dispatchSearchGtmEvent(value || displayValue);
      onSearch?.();
    }
  };

  // handle keyboard item selection in history and suggestions
  const keyHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setCursor((c) => c + 1);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setCursor((c) => (c === -1 ? c : c - 1));
    } else if (e.key === 'Enter') {
      e.stopPropagation();
      const selectedItem = document.getElementById('selected-item');
      if (selectedItem) {
        selectedItem.click();
      } else {
        e.preventDefault();
        search();
        blur();
      }
    }
  };

  const handleFocusOnEnterInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      focus();
      onFocus();
    }
    if (event.key === 'Escape') {
      event.stopPropagation();
      setFocused(false);
    }
  };

  const clearInputAndFocus = () => {
    setValue(INITIAL_INPUT_VALUE);
    setDisplayValue(INITIAL_INPUT_VALUE);
    resetCursor();
    focus();
  };

  // A change in `initialValue` means a change in the route
  // In this case we want to reset the input and blur, similar to how everything is on initial render
  useEffect(() => {
    setValue(initialValue);
    setDisplayValue(initialValue);
    resetCursor();
    blur();
  }, [initialValue]);

  // A change in `autofocus` should focus the input if need be
  // Caution: This effect needs to run after the previous effect, which blurs the input
  // Otherwise the call of `blur` overrides the call of `focus`
  useEffect(() => {
    if (autofocus) {
      focus();
    }
  }, [autofocus]);

  const relevantPopup =
    focused && (debouncedValue === INITIAL_INPUT_VALUE ? 'history' : 'suggestions');

  const { isInspiringSearchEnabled } = useInspiringSearchSettings();

  return (
    <>
      <Notification open={showAlert} onClose={hideAlert} severity="error" autoHideDuration={2000}>
        {formatMessage(messages.searchBarEmptyAlert)}
      </Notification>
      <Box
        tabIndex={0}
        sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        data-testid="searchfield-wrapper"
        onBlur={onBlur}
        onKeyDown={handleFocusOnEnterInput}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onFocus();
        }}
        ref={wrapperRef}
      >
        {isInspiringSearchEnabled && !(value || displayValue) && <SearchAnimatedPlaceholder />}
        <TextField
          sx={{
            pt: 0,
            fieldset: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderColor: focused ? 'primary.main' : 'grey.dark',
            },
          }}
          onKeyDown={keyHandler}
          value={displayValue}
          fullWidth
          onChange={(event) => {
            updateInput(event.target.value);
          }}
          ref={inputEl}
          inputProps={{
            onFocus: () => onFocus(),
            tabIndex: -1,
            'data-testid': 'searchfield-input',
            'aria-label': formatMessage(messages.ariaLabelInput),
            enterKeyHint: 'search',
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            autoComplete: 'off',
            endAdornment: (value || displayValue) && (
              <CloseIconButton
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  clearInputAndFocus();
                }}
                data-testid="searchfield-reset-button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    clearInputAndFocus();
                  }
                }}
                sx={{ color: 'text.primary' }}
                aria-label={formatMessage(messages.ariaLabelResetButton)}
              />
            ),
          }}
          placeholder={isInspiringSearchEnabled ? undefined : formatMessage(messages.placeholder)}
        />
        <IconButton
          icon={<Search32 sx={{ color: focused ? 'primary.contrastText' : undefined }} />}
          size="large"
          sx={{
            flexShrink: 0,
            ml: '-1px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderColor: focused ? 'primary.main' : 'grey.dark',
            padding: 'inherit',
            '.MuiButton-startIcon > *:nth-of-type(1)': {
              fontSize: 32,
            },
          }}
          color={focused ? 'primary' : undefined}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              setFocused(false);
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
            search();
            setFocused(false);
          }}
          aria-label={formatMessage(messages.ariaLabelButton)}
          data-testid="searchfield-button"
        />
        {focused && jwtToken && !isolated && (
          <ErrorBoundary>
            <Suspense fallback={<LoadingFallback />}>
              <MenuList
                data-testid="searchfield-popover-list"
                sx={{
                  py: 0,
                  bgcolor: 'grey.light',
                  boxShadow: 1,
                  position: 'absolute',
                  zIndex: (theme) => theme.zIndex.appBar + 2, // higher than the flyout navigation (filter popover is appbar, appbar + 1 is flyout navigation)
                  top: '100%',
                  left: 0,
                  width: relevantPopup === 'suggestions' ? ['100%', '100%', '170%'] : '100%',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setFocused(false);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              >
                {relevantPopup === 'suggestions' && (
                  <SearchSuggestions
                    jwtToken={jwtToken}
                    cursor={cursor}
                    onAccept={(
                      selectedSuggestion?: Suggestion,
                      allSuggestions?: Result[],
                      suggestionType?: GlycerinSuggestionType,
                    ): void => {
                      dispatchSearchGtmEvent(
                        value || displayValue,
                        selectedSuggestion,
                        allSuggestions,
                        suggestionType,
                      );
                      onSearch?.();
                    }}
                    setValue={externalChangeValue}
                    searchValue={debouncedValue}
                  />
                )}
                {relevantPopup === 'history' && isInspiringSearchEnabled && (
                  <InspiringSearch
                    // jwtToken={jwtToken}
                    cursor={cursor}
                    onAccept={(acceptedValue) => {
                      updateInput(acceptedValue);
                      onSearch?.();
                    }}
                    setValue={externalChangeValue}
                  />
                )}
                {relevantPopup === 'history' && (
                  <SearchHistory
                    jwtToken={jwtToken}
                    cursor={cursor}
                    onAccept={(acceptedValue) => {
                      updateInput(acceptedValue);
                      onSearch?.();
                    }}
                    setValue={externalChangeValue}
                  />
                )}
              </MenuList>
            </Suspense>
          </ErrorBoundary>
        )}
      </Box>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchField;
